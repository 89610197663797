import { useEffect, useState } from "react";
import { ScrollRestoration, useParams } from "react-router-dom"
import { SEDES } from '../data/SEDES';


function ActividadesSede({ activities }) {
    const [currentIndex, setCurrentIndex] = useState(0)

    return (
        <>
            <h2 className="fw-bold mb-2 text-primary text-center">HORARIO DE ACTIVIDADES</h2>
            <ul className="nav nav-pills justify-content-center gap-3">
                {
                    activities.map((act, i) => {
                        return (
                            <li key={act.title} className="nav-item" style={{ cursor: "pointer" }}
                                onClick={() => { setCurrentIndex(i) }}>
                                <span className={"nav-link" + ((activities[currentIndex].title === act.title) ? " active" : "")}>{act.title}</span>
                            </li>
                        )
                    })
                }
            </ul>
            <img style={{ width: "100%", objectFit: "cover", display: "block" }} src={activities[currentIndex].src} alt={activities[currentIndex].title} />
        </>
    )
}


export default function SedeDetalle() {
    const { id } = useParams();
    let [sede, setSede] = useState({
        id: "",
        gmaps: "",
        name: ""
    });

    useEffect(() => {
        let aux = SEDES.filter(obj => { return obj.id === id });
        if (aux.length > 0) {
            setSede({
                ...aux[0]
            })
        }
    }, [id]);

    return (
        <>
            <ScrollRestoration />
            <section style={{ position: "relative", paddingTop: "5rem" }}>
                <div className="mt-5 py-5 text-white text-center">
                    <h1 className="fw-bold text-shadow-embossed">SEDE {sede["name"].toUpperCase()}</h1>
                </div>
                <img className="img-bg-container img-filter-bright-75" alt="header"
                    src="https://firebasestorage.googleapis.com/v0/b/b2-portal.appspot.com/o/landing%2Fassets%2Ffotor_2023-4-16_8_9_59.jpg?alt=media" />
            </section>
            <section className="b2-container my-5 px-4">
                <div className="d-flex flex-column gap-5">
                    <div className="row gap-5">
                        <div className="col-md text-white p-0">
                            {
                                sede.video ?
                                    <video className="w-100" controls
                                        style={{ objectFit: "cover", display: "block" }}
                                        alt="sede" src={sede.video} />
                                    :
                                    <img className="w-100" style={{ objectFit: "cover", display: "block" }}
                                        alt="sede" src={sede.photo ? sede.photo : "sven-mieke-tc1eFr68CTU-unsplash.jpg"} />
                            }

                        </div>
                        <div className="col-md">
                            <p className='mb-3'><i className="bi bi-geo-alt-fill text-primary"></i> {sede.address}</p>
                            <p className='mb-3'><i className="bi bi-whatsapp text-primary"></i> {sede.phone}</p>
                            {sede.email && <p className='mb-3'><i className="bi bi-at text-primary"></i> {sede.email}</p>}

                            <p>{sede.description}</p>

                            {
                                sede["schedule"] &&
                                <>
                                    <p className="fw-bold text-primary">HORARIOS</p>
                                    <div className="row gap-2">
                                        {
                                            sede["schedule"].map(obj => {
                                                return (
                                                    <div key={obj["days"]} className="col">
                                                        <p><i className="bi bi-calendar-week text-primary"></i> {obj["days"]}</p>
                                                        <p><i className="bi bi-clock text-primary"></i> {obj["hours"]}</p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                    <div className="row p-0 m-0">
                        <h2 className="fw-bold mb-4 text-primary text-center">UBÍCANOS</h2>

                        <iframe
                            src={sede["gmaps"]}
                            className="p-0"
                            height={450}
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            referrerPolicy="no-referrer-when-downgrade"
                            title={sede["id"]}
                        />
                    </div>
                    {
                        sede.activities && sede.activities.length > 0 &&
                        <div className="row gap-3">
                            <ActividadesSede activities={sede.activities}></ActividadesSede>
                        </div>
                    }
                </div>

            </section>

        </>
    )
}