import { useLocation } from "react-router-dom";

export default function PromoToast() {
  const location = useLocation();
  return (
    <>
      {location.pathname === "/" &&
        <div
          className="promo-toast rounded p-3 filter-shadow-embossed"
          style={{ textWrap: "nowrap" }}
        >
          <div className="row gap-2 flex-wrap">
            <div className="col">
              <div style={{ fontSize: "1.2rem", fontWeight: 600 }}>
                NUEVA SEDE EDGE:
                <br />
                EL TREBÓL
                <br />
                Javier Prado
              </div>
            </div>
            <div className="col" style={{ height: "100%", alignItems: "center" }}>
              <a
                href="https://api.whatsapp.com/send?phone=51938079688&text=¡Hola! Deseo información sobre la preventa El Trébol"
                target="_blank"
                className="btn btn-primary text-white"
                style={{ fontSize: "1.2rem", fontWeight: 600 }}
              >
                INFO AQUI
              </a>
            </div>
          </div>
        </div>
      }
    </>
  );
}
